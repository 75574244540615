export type Locale = "en" | "nl";
export const DEFAULT_LOCALE = "nl";

const translations = {
	anthracite: {
		en: "Anthracite",
		nl: "Antraciet",
	},
	grey: {
		en: "Grey",
		nl: "Grijs",
	},
	white: {
		en: "White",
		nl: "Wit",
	},
	duo: {
		en: "Duo",
		nl: "Duo",
	},
	left: {
		en: "Left",
		nl: "Links",
	},
	right: {
		en: "Right",
		nl: "Rechts",
	},
	front: {
		en: "Front",
		nl: "Voor",
	},
	back: {
		en: "Back",
		nl: "Achter",
	},
	walls: {
		en: "Walls",
		nl: "Wanden",
	},
	roof: {
		en: "Roof",
		nl: "Dak",
	},
	color: {
		en: "Color",
		nl: "Kleur",
	},
	colors: {
		en: "Colors",
		nl: "Kleuren",
	},
	summary: {
		en: "Summary",
		nl: "Overzicht",
	},
	selectWall: {
		en: "Please select a wall",
		nl: "Gelieve een wand te selecteren",
	},
	selectedWall: {
		en: "Wall selected: {selectedWall}",
		nl: "Geselecteerde wand: {selectedWall}",
	},
	backWallWarning: {
		en: "The back wall cannot be edited",
		nl: "De achterwand kan niet gewijzigd worden",
	},
	requestQuote: {
		en: "Request quote",
		nl: "Offerte aanvragen",
	},
	hideWalls: {
		en: "Hide walls",
		nl: "Wanden verbergen",
	},
	showWalls: {
		en: "Show walls",
		nl: "Wanden tonen",
	},
	hideGrid: {
		en: "Hide grid",
		nl: "Raster verbergen",
	},
	showGrid: {
		en: "Show grid",
		nl: "Raster tonen",
	},
	hideMeasurements: {
		en: "Show measurements",
		nl: "Afmetingen verbergen",
	},
	showMeasurements: {
		en: "Show measurements",
		nl: "Afmetingen tonen",
	},
	surface: {
		en: "Surface area",
		nl: "Oppervlakte",
	},
	name: {
		en: "Name",
		nl: "Naam",
	},
	firstname: {
		en: "Name",
		nl: "Voornaam",
	},
	surname: {
		en: "Surname",
		nl: "Naam",
	},
	company: {
		en: "Company",
		nl: "Bedrijf",
	},
	street: {
		en: "Street",
		nl: "Straat",
	},
	number: {
		en: "Number",
		nl: "Nummer",
	},
	phone: {
		en: "Phone Number",
		nl: "Telefoonnummer",
	},
	email: {
		en: "Email Address",
		nl: "E-mailadres",
	},
	postalCode: {
		en: "Postal Code",
		nl: "Postcode",
	},
	town: {
		en: "Town",
		nl: "Plaats",
	},
	country: {
		en: "Country",
		nl: "Land",
	},
	empty: {
		en: "Empty",
		nl: "Leeg",
	},
	comments: {
		en: "Comments",
		nl: "Opmerkingen",
	},
	requiredFieldsHelp: {
		en: "Fields marked with * are required",
		nl: "Velden gemarkeerd met * zijn verplicht",
	},
	send: {
		en: "Send",
		nl: "Verzenden",
	},
	invalidEmail: {
		en: "Invalid email address",
		nl: "Ongeldig e-mailadres",
	},
	nameRequired: {
		en: "Name is required",
		nl: "Voornaam is verplicht",
	},
	surnameRequired: {
		en: "Surname is required",
		nl: "Naam is verplicht",
	},
	phoneRequired: {
		en: "Phone number is required",
		nl: "Telefoonnummer is verplicht",
	},
	countryRequired: {
		en: "Country is required",
		nl: "Land is verplicht",
	},
	contactDetails: {
		en: "Contact details",
		nl: "Contactgegevens",
	},
	somethingWentWrong: {
		en: "Something went wrong",
		nl: "Er is iets fout gegaan",
	},
	pdfSent: {
		en: "A PDF has beent sent to the supplied email address",
		nl: "Een PDF is verstuurd naar het opgegeven e-mailadres",
	},
	close: {
		en: "Close",
		nl: "Sluiten",
	},
	downloadPDF: {
		en: "Download PDF",
		nl: "PDF downloaden",
	},
	reset: {
		en: "Reset",
		nl: "Reset",
	},
	confirmReset: {
		en: "Are you sure you want to reset?",
		nl: "Weet je zeker dat je wilt resetten?",
	},
	return: {
		en: "Return",
		nl: "Terug",
	},
	confirmReturn: {
		en: "Are you sure you want to return?",
		nl: "Weet je zeker dat je terug wilt gaan?",
	},
	loungeFront: {
		en: "Lounge front",
		nl: "Lounge voorkant",
	},
	loungeBack: {
		en: "Lounge back",
		nl: "Lounge achterkant",
	},
	loungeSide: {
		en: "Lounge side",
		nl: "Lounge zijkant",
	},
	noDrainageWarning: {
		en: "No drainage was added",
		nl: "Er ontbreekt een wand met drainage",
	},
	wallsMissingWarning: {
		en: "One or more walls are missing",
		nl: "Een of meerdere wanden ontbreken",
	},
	singleOpenWallWarning: {
		en: "Multiple open walls are not allowed",
		nl: "Meerdere open wanden zijn niet toegelaten",
	},
	innerWall: {
		en: "Inner wall",
		nl: "Binnenwand",
	},
	electricity: {
		en: "Electricity",
		nl: "Elektriciteit",
	},
	continue: {
		en: "Continue",
		nl: "Verder",
	},
	price: {
		en: "Price",
		nl: "Prijs",
	},
	dimensions: {
		en: "Dimensions",
		nl: "Afmetingen",
	},
} satisfies Record<string, Record<Locale, string>>;

export default translations;
