import { create } from "zustand";
import { mountStoreDevtool } from "simple-zustand-devtools";
import { createJSONStorage, persist } from "zustand/middleware";
import store, { Actions, State } from "./store";
import { immer } from "zustand/middleware/immer";

const isDevMode = import.meta.env.DEV;

export { selectors } from "./selectors";
const persistConfig = {
	name: "app-store",
	version: 1,
	storage: createJSONStorage(() => sessionStorage),
	partialize: (state: State & Actions) =>
		({
			configuration: state.configuration,
		}) as State & Actions,
};

export const useAppStore = create<State & Actions>()(persist(immer(store), persistConfig));

if (isDevMode) {
	mountStoreDevtool("AppStore", useAppStore);
}
