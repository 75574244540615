import data from "./roofs.json" with { type: "json" };

export type Roof = {
	id: string;
	name: string;
	depth: number;
	width: number;
	loungeWidth: number;
	price: number;
	iconURL?: string;
};
export type RoofJSON = Record<string, Roof>;

const byId = data as RoofJSON;
const walls = { byId };

export default walls;
