import { useLocation } from "@tanstack/react-router";
import translations, { DEFAULT_LOCALE } from "@/translations";

const steps = [
	{ name: translations.walls[DEFAULT_LOCALE], route: "/walls" },
	{ name: translations.colors[DEFAULT_LOCALE], route: "/colors" },
	{ name: translations.summary[DEFAULT_LOCALE], route: "/summary" },
];

const useNavigation = () => {
	const location = useLocation();
	const getStep = (index: number) => (index < 0 || index >= steps.length ? undefined : steps[index]);
	const currentStep = steps.findIndex((step) => step.route === location.pathname);
	const prev = getStep(currentStep - 1)?.route;
	const next = getStep(currentStep + 1)?.route;

	return { prev, next, steps };
};

export default useNavigation;
