import { WallLocation } from "@/config/walls";
import { useAppStore } from "@/stores/appStore";
import { isUndefined } from "@/utils";
import styled, { css } from "styled-components";

type PolygonProps = {
	$isActive: boolean;
	$isTriangle: boolean;
};

const Polygon = styled.polygon<PolygonProps>`
	stroke: ${({ theme }) => theme.colors.grey};
	fill: ${({ theme }) => theme.colors.white};
	transition: fill 0.15s ease;

	${({ $isTriangle, $isActive, theme }) =>
		$isTriangle &&
		css`
			fill: ${$isActive ? theme.colors.primary : theme.colors.grey};
		`}
`;

type IconProps = {
	location: WallLocation;
	width?: number;
	height?: number;
};

export function ShedWithLoungeIcon({ location, ...props }: IconProps) {
	const selectedWall = useAppStore((store) => store.selectedWall);

	const calculateProps = (...matches: WallLocation[]): PolygonProps => {
		const $isTriangle = !isUndefined(location) && matches.includes(location);
		const $isActive = location === selectedWall;

		return { $isActive, $isTriangle };
	};

	return (
		<svg {...props} xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 101.77 98">
			<Polygon {...calculateProps("back", "left")} points="34.26 20.2 34.26 58.6 67.51 39.4 34.26 20.2" />
			<Polygon {...calculateProps("left", "loungeFront")} points="34.26 58.6 67.51 77.8 67.51 39.4 34.26 58.6" />
			<Polygon {...calculateProps("back")} points="34.26 20.2 67.51 39.4 67.51 1 34.26 20.2" />
			<Polygon {...calculateProps("right")} points="67.51 1 67.51 39.4 67.51 39.4 100.77 20.2 100.77 20.2 67.51 1" />
			<Polygon
				{...calculateProps("front")}
				points="100.77 58.6 67.51 39.4 67.51 39.4 67.51 77.8 100.77 58.6 100.77 58.6"
			/>
			<Polygon {...calculateProps("front", "right")} points="100.77 20.2 67.51 39.4 100.77 58.6 100.77 20.2" />
			<Polygon {...calculateProps("loungeSide", "loungeBack")} points="1 39.4 1 77.8 34.26 58.6 1 39.4" />
			<Polygon {...calculateProps("loungeSide")} points="1 77.8 34.26 97 34.26 58.6 1 77.8" />
			<Polygon {...calculateProps("loungeBack")} points="1 39.4 34.26 58.6 34.26 20.2 1 39.4" />
			<Polygon
				{...calculateProps("loungeFront")}
				points="67.51 77.8 34.26 58.6 34.26 58.6 34.26 97 67.51 77.8 67.51 77.8"
			/>
		</svg>
	);
}

export function ShedIcon({ location, ...props }: IconProps) {
	const selectedWall = useAppStore((store) => store.selectedWall);

	const calculateProps = (...matches: WallLocation[]): PolygonProps => {
		const $isTriangle = !isUndefined(location) && matches.includes(location);
		const $isActive = location === selectedWall;

		return { $isActive, $isTriangle };
	};
	return (
		<svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68.51 78.8">
			<Polygon {...calculateProps("left", "back")} points="1 20.2 1 58.6 34.26 39.4 1 20.2" />
			<Polygon {...calculateProps("left")} points="1 58.6 34.26 77.8 34.26 39.4 1 58.6" />
			<Polygon {...calculateProps("back")} points="1 20.2 34.26 39.4 34.26 1 1 20.2" />
			<Polygon {...calculateProps("right")} points="34.26 1 34.26 39.4 34.26 39.4 67.51 20.2 67.51 20.2 34.26 1" />
			<Polygon {...calculateProps("front", "right")} points="67.51 20.2 34.26 39.4 67.51 58.6 67.51 20.2" />
			<Polygon
				{...calculateProps("front")}
				points="67.51 58.6 34.26 39.4 34.26 39.4 34.26 77.8 67.51 58.6 67.51 58.6"
			/>
		</svg>
	);
}
