import { WallSelect, WallTabs } from "@/components/pages/walls/WallsPanel";
import WallOptions from "@/components/pages/walls/WallsPanel/WallOptions";
import { createFileRoute } from "@tanstack/react-router";
import styled from "styled-components";

export const Route = createFileRoute("/_configurator/walls")({
	component: Walls,
});

const Container = styled.div`
	display: flex;
	gap: 1rem;
	flex-direction: column;
	align-items: center;
	max-height: 100%;
`;

function Walls() {
	return (
		<Container>
			<WallTabs />
			<WallOptions />
			<WallSelect />
		</Container>
	);
}
