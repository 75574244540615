import { WallLocation } from "@/config/walls";
import { selectors, useAppStore } from "@/stores/appStore";
import styled from "styled-components";
import { ShedIcon, ShedWithLoungeIcon } from "../WallIcon";
import WallButton from "./WallButton";

export default function WallTabs() {
	const walls = useAppStore(selectors.getAllWalls);
	const isLounge = useAppStore(selectors.selectedIsLounge);
	const options: WallLocation[] = Object.keys(walls) as WallLocation[];
	const Icon = isLounge ? ShedWithLoungeIcon : ShedIcon;

	return (
		<WallTabsContainer>
			{options.map((option) => (
				<WallButton key={option} location={option}>
					<Icon location={option} width={48} height={48} />
				</WallButton>
			))}
		</WallTabsContainer>
	);
}

const WallTabsContainer = styled.div`
	display: flex;
	gap: 1rem;
	justify-content: flex-start;

	&:hover {
		cursor: pointer;
	}
`;
