import CONFIG from "@/config";
import { MeshPhysicalMaterial, MeshStandardMaterial } from "three";

export const glassMaterial = new MeshPhysicalMaterial({
	metalness: 0.2,
	roughness: 0,
	transmission: 0.99,
	ior: 1.5,
	thickness: 0.01,
	depthWrite: false,
});

export const anthraciteMaterial = new MeshStandardMaterial({
	color: CONFIG.colors.anthracite,
	roughness: 1,
	metalness: 0,
});

export const metalMaterial = new MeshStandardMaterial({
	color: "lightgrey",
	roughness: 0.5,
	metalness: 1,
});
