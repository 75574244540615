import Button from "@/components/primitives/Button";
import { WallLocation } from "@/config/walls";
import { selectors, useAppStore } from "@/stores/appStore";
import translations, { DEFAULT_LOCALE } from "@/translations";
import { createFileRoute } from "@tanstack/react-router";
import { useRef } from "react";
import styled from "styled-components";
import QuoteModal from "@/components/pages/summary/QuoteModal";
import { useValidation } from "@/hooks/useValidation";
import Callout from "@/components/primitives/Callout";

export const Route = createFileRoute("/_configurator/summary")({
	component: Summary,
});

const Data = styled.td`
	font-weight: 300;
`;

const Row = styled.tr`
	display: flex;
	justify-content: space-between;
	padding: ${({ theme }) => theme.padding.small};
`;

const TableContainer = styled.table`
	display: flex;
	flex-direction: column;
	${Row}:nth-child(even) {
		background-color: ${({ theme }) => theme.colors.light_grey};
	}
`;

function Table({ rows }: { rows: string[][] }) {
	return (
		<TableContainer>
			<tbody>
				{rows.map((row, i) => (
					<Row key={i}>
						{row.map((cell, j) => (
							<Data key={j}>{cell}</Data>
						))}
					</Row>
				))}
			</tbody>
		</TableContainer>
	);
}

function WallsTable() {
	const encodings = useAppStore(selectors.getAllWallEncodings);

	return (
		<Table
			rows={Object.entries(encodings).map(([location, encoding]) => [
				translations[location as WallLocation][DEFAULT_LOCALE],
				encoding,
			])}
		/>
	);
}

const Title = styled.h3`
	padding-bottom: ${({ theme }) => theme.gap.small};
`;

const Section = styled.div`
	margin-bottom: ${({ theme }) => theme.gap.large};
`;

function Summary() {
	const roof = useAppStore(selectors.getRoofBySelected);
	const color = useAppStore(selectors.getSelectedColor);
	const surface = (roof.width * roof.depth).toFixed(2);
	const modalRef = useRef<HTMLDialogElement>(null);
	const { isValid, message } = useValidation();

	return (
		<>
			<Section>
				<Title>{translations.roof[DEFAULT_LOCALE]}</Title>
				<Table
					rows={[
						[translations.name[DEFAULT_LOCALE], roof.name],
						[translations.surface[DEFAULT_LOCALE], `${surface} m²`],
					]}
				/>
			</Section>

			<Section>
				<Title>{translations.walls[DEFAULT_LOCALE]}</Title>
				<WallsTable />
			</Section>

			<Section>
				<Title>{translations.color[DEFAULT_LOCALE]}</Title>
				<p>{translations[color][DEFAULT_LOCALE]}</p>
			</Section>

			<Section>
				<Button variant="primary" onClick={() => modalRef.current?.showModal()}>
					{translations.requestQuote[DEFAULT_LOCALE]}
				</Button>
				{isValid || <Callout variant="warning">{message}</Callout>}
			</Section>

			<QuoteModal ref={modalRef} />
		</>
	);
}
