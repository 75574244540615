import { ENVIRONMENT_FILE } from "@/globals";
import { useThree } from "@react-three/fiber";
import { EquirectangularReflectionMapping, SRGBColorSpace, TextureLoader } from "three";

const texture = new TextureLoader().load(ENVIRONMENT_FILE);
texture.mapping = EquirectangularReflectionMapping;
texture.colorSpace = SRGBColorSpace;

export default function Lights() {
	const scene = useThree((state) => state.scene);

	scene.environment = texture;
	scene.environmentIntensity = 1;

	return (
		<directionalLight
			castShadow
			position={[30, 50, 50]}
			shadow-mapSize-height={512}
			shadow-mapSize-width={512}
			ref={(light) => light?.lookAt(0, 0, 0)}
		/>
	);
}
