import { Link } from "@tanstack/react-router";
import { ComponentProps, FunctionComponent } from "react";

interface ArrowProps {
	direction: "left" | "right";
}

const Arrow: FunctionComponent<ArrowProps> = ({ direction }) => {
	const leftPath = (
		<path
			fill="currentColor"
			d="m11.028 18.677-9.239-8.47h17.534v-1H1.79L11.028.738l-.675-.739-10.146 9.3a.528.528 0 0 0-.087.08.5.5 0 0 0 0 .64.516.516 0 0 0 .087.08l10.146 9.315Z"
		/>
	);

	const rightPath = (
		<path
			fill="currentColor"
			d="m8.294 18.677 9.239-8.47H0v-1h17.533L8.295.738l.675-.739 10.146 9.3a.528.528 0 0 1 .087.08.5.5 0 0 1 .116.32.5.5 0 0 1-.116.32.516.516 0 0 1-.087.08l-10.146 9.3Z"
		/>
	);

	return (
		<span>
			<svg viewBox="0 0 19.323 19.414">{direction === "left" ? leftPath : rightPath}</svg>
		</span>
	);
};

const ArrowLink: FunctionComponent<ArrowProps & ComponentProps<typeof Link>> = ({ direction, ...linkProps }) => (
	<Link {...linkProps}>
		<Arrow direction={direction} />
	</Link>
);

export default ArrowLink;
