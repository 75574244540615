import CONFIG from "@/config";
import { WallLocation } from "@/config/walls";
import useThree from "@/hooks/useThree";
import { useAppStore } from "@/stores/appStore";
import { startAnimation } from "@/utils";
import CameraControls from "camera-controls";
import { MouseEventHandler, ReactNode, useCallback } from "react";
import styled from "styled-components";

type WallProps = {
	location: WallLocation;
	children: ReactNode;
};

export default function WallButton({ location, children }: WallProps) {
	const controls = useThree()?.controls;
	const toggleSelectedWall = useAppStore((store) => store.toggleSelectedWall);

	const focusCamera = useCallback(
		(location: WallLocation) => {
			const position = getCameraPositionFor(location);

			startAnimation(() => {
				if (controls instanceof CameraControls) {
					void controls.setPosition(position.x, position.y, position.z, true);
				}
			});
		},
		[controls]
	);

	const handleSelectWall = useCallback<MouseEventHandler>(
		(event) => {
			event.preventDefault();
			toggleSelectedWall(location);
			focusCamera(location);
		},
		[toggleSelectedWall, location, focusCamera]
	);

	return <StyledButton onClick={handleSelectWall}>{children}</StyledButton>;
}

function getCameraPositionFor(location: WallLocation) {
	const position = {
		front: { x: 0, y: CONFIG.cameraHeight, z: CONFIG.cameraDistance },
		left: { x: -CONFIG.cameraDistance, y: CONFIG.cameraHeight, z: 0 },
		right: { x: CONFIG.cameraDistance, y: CONFIG.cameraHeight, z: 0 },
		back: { x: 0, y: CONFIG.cameraHeight, z: -CONFIG.cameraDistance },
		loungeBack: { x: 0, y: CONFIG.cameraHeight, z: -CONFIG.cameraDistance },
		loungeSide: { x: -CONFIG.cameraDistance, y: CONFIG.cameraHeight, z: 0 },
		loungeFront: { x: 0, y: CONFIG.cameraHeight, z: CONFIG.cameraDistance },
	}[location];

	return position;
}

const StyledButton = styled.button`
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
`;
