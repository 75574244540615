import { ComponentProps } from "react";
import styled, { css } from "styled-components";

const ImageContainer = styled.img<{ $fit?: "cover" | "contain"; $clickable: boolean }>`
	width: 100%;
	object-fit: ${({ $fit = "cover" }) => $fit};
	aspect-ratio: 1;

	${({ $clickable }) =>
		$clickable &&
		css`
			cursor: pointer;
		`}
`;

type ImageProps = Omit<ComponentProps<typeof ImageContainer>, "$clickable">;
export default function Image(props: ImageProps) {
	return <ImageContainer $clickable={"onClick" in props} {...props} />;
}
