import { useCursor } from "@react-three/drei";
import { GroupProps, ThreeEvent } from "@react-three/fiber";
import { ComponentProps, createContext, useCallback, useContext, useState } from "react";

type EventHandler = (event: ThreeEvent<PointerEvent>) => void;
type CursorProviderProps = Omit<ComponentProps<typeof CursorContext.Provider>, "value">;

// eslint-disable-next-line @typescript-eslint/no-empty-function
const nullFunc: EventHandler = () => {};

const CursorContext = createContext<{
	onPointerEnter: EventHandler;
	onPointerLeave: EventHandler;
}>({
	onPointerEnter: nullFunc,
	onPointerLeave: nullFunc,
});

export const CursorProvider = ({ children }: CursorProviderProps) => {
	const [hoveredCount, setHoveredCount] = useState(0);
	useCursor(hoveredCount > 0);

	const onPointerEnter = useCallback<EventHandler>(() => {
		setHoveredCount((prev) => prev + 1);
	}, []);
	const onPointerLeave = useCallback<EventHandler>(() => {
		setHoveredCount((prev) => prev - 1);
	}, []);

	return <CursorContext.Provider value={{ onPointerEnter, onPointerLeave }}>{children}</CursorContext.Provider>;
};

export default function WithCursor({ children, ...props }: GroupProps) {
	const { onPointerEnter, onPointerLeave } = useContext(CursorContext);

	return (
		<group {...props} onPointerEnter={onPointerEnter} onPointerLeave={onPointerLeave}>
			{children}
		</group>
	);
}
