import styled from "styled-components";

const Checkbox = styled.input.attrs({ type: "checkbox" })`
	position: relative;
	width: 1.5rem;
	height: 1.5rem;

	outline: none;
	appearance: none;

	background-color: ${({ theme }) => theme.colors.background_light};

	&:hover {
		cursor: pointer;
		background-color: ${({ theme }) => theme.colors.grey};
	}

	&:checked {
		background-color: ${({ theme }) => theme.colors.primary};

		&::before {
			content: "✓";
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			color: ${({ theme }) => theme.colors.background_light};
		}
	}
`;

export default Checkbox;
