import { StateCreator } from "zustand";
import CONFIG, { ColorVariant, RoofCode } from "@/config";
import { WallCode, WallLocation } from "@/config/walls";
import { isUndefined } from "@/utils";

const isDevMode = import.meta.env.DEV;

export type WallData = {
	id?: WallCode;
	electricity?: boolean;
	innerWall?: boolean;
};

export type WallConfiguration = Record<WallLocation, WallData>;
export type Configuration = {
	roof: RoofCode;
	walls: WallConfiguration;
	color: ColorVariant;
};

const defaultConfiguration: Configuration = {
	roof: CONFIG.roofs.byId["00"].id,
	walls: {
		front: { id: undefined, electricity: false, innerWall: false },
		left: { id: undefined, electricity: false, innerWall: false },
		right: { id: undefined, electricity: false, innerWall: false },
		back: { id: undefined, electricity: false, innerWall: false },
		loungeFront: { id: undefined, electricity: false, innerWall: false },
		loungeBack: { id: undefined, electricity: false, innerWall: false },
		loungeSide: { id: undefined, electricity: false, innerWall: false },
	},
	color: "anthracite",
};

export type State = {
	configuration: Configuration;
	selectedWall?: WallLocation;
	showGrid: boolean;
	showWalls: boolean;
	showMeasurements: boolean;
	isDevMode: boolean;
};

type Toggle = {
	[K in keyof State]: State[K] extends boolean ? K : never;
}[keyof State];

export type Actions = {
	toggle: (key: Toggle) => void;
	setRoof: (roofCode: RoofCode) => void;
	setWallByLocation: (location: WallLocation, wallId: WallCode) => void;
	setWallBySelected: (wallId: WallCode) => void;
	toggleSelectedWall: (location: WallLocation) => void;
	toggleWallBySelected: (wallId: WallCode) => void;
	toggleWallOptionBySelected: (option: "electricity" | "innerWall") => void;
	setColor: (color: ColorVariant) => void;
	reset: () => void;
};

const store: StateCreator<
	State & Actions,
	[["zustand/persist", unknown], ["zustand/immer", unknown]],
	[],
	State & Actions
> = (set) => ({
	configuration: defaultConfiguration,
	isDevMode,
	showGrid: true,
	showWalls: true,
	showMeasurements: false,
	selectedWall: "front",
	toggle: (key) =>
		set((state) => {
			if (isUndefined(key)) return;

			state[key] = !state[key];
		}),
	setRoof: (roofCode) =>
		set((state) => {
			state.configuration = { ...defaultConfiguration, roof: roofCode };
		}),
	toggleSelectedWall: (location) =>
		set((state) => {
			state.selectedWall = state.selectedWall !== location ? location : undefined;
		}),
	setWallByLocation: (location, wallId) =>
		set((state) => {
			state.configuration.walls[location].id = wallId;
		}),
	setWallBySelected: (wallId) =>
		set((state) => {
			if (isUndefined(state.selectedWall)) return;

			state.configuration.walls[state.selectedWall].id = wallId;
		}),
	toggleWallOptionBySelected: (option: "electricity" | "innerWall") =>
		set((state) => {
			if (isUndefined(state.selectedWall)) return;
			const wall = state.configuration.walls[state.selectedWall];
			wall[option] = !wall[option];
		}),
	toggleWallBySelected: (wallId) =>
		set((state) => {
			if (isUndefined(state.selectedWall)) return;
			const wall = state.configuration.walls[state.selectedWall];
			wall.id = wallId !== wall.id ? wallId : undefined;
		}),
	setColor: (color) =>
		set((state) => {
			state.configuration.color = color;
		}),
	reset: () =>
		set((state) => {
			const { roof: _, ...overrides } = defaultConfiguration;
			state.configuration = { ...state.configuration, ...overrides };
		}),
});

export default store;
