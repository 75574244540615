import { Canvas } from "@react-three/fiber";
import Performance from "./Performance";
import Lights from "./Lights";
import Controls from "./Controls";
import Effects from "./Effects";
import Product from "./Product";
import Grid from "./Grid";
import { Suspense } from "react";
import { ErrorBoundary } from "@sentry/react";
import { Box } from "@react-three/drei";
import { theme } from "@/theme";
import Floor from "./Floor";
import { CursorProvider } from "./Utils/WithCursor";
import Camera from "./Camera";
import { SyncStore } from "@/hooks/useThree";

export default function Scene() {
	return (
		<Canvas
			dpr={1.5}
			shadows
			frameloop="demand"
			data-testid="canvas"
			gl={{ powerPreference: "high-performance", antialias: false, stencil: false, depth: false }} // For compatibility with postprocessing
		>
			<color args={[theme.colors.background_light]} attach="background" />
			<Performance>
				<Camera />
				<Controls />
				<Lights />
				<Effects />
				<ErrorBoundary fallback={<Box material-color="red" />}>
					<CursorProvider>
						<Suspense fallback={null}>
							<Product />
						</Suspense>
					</CursorProvider>
				</ErrorBoundary>
				<Grid />
				<Floor />
				<SyncStore />
			</Performance>
		</Canvas>
	);
}
