import CONFIG from "@/config";
import Pillar from "../Pillar";
import Wall from "../Wall";
import { FunctionComponent } from "react";
import { Roof } from "@/config/roofs";

const ShedWithLounge: FunctionComponent<Roof> = ({ width, loungeWidth, depth }) => {
	return (
		<>
			<group position-x={(loungeWidth - CONFIG.wallThickness) / 2}>
				<Pillar
					type="TV06"
					position={[-(width - CONFIG.wallThickness) / 2, 0, -(depth - CONFIG.wallThickness) / 2]}
					rotation-y={(-0 * Math.PI) / 2}
				/>
				<Pillar
					type="HV06"
					position={[(width - CONFIG.wallThickness) / 2, 0, -(depth - CONFIG.wallThickness) / 2]}
					rotation-y={(-1 * Math.PI) / 2}
				/>
				<Pillar
					type="HV06"
					position={[(width - CONFIG.wallThickness) / 2, 0, (depth - CONFIG.wallThickness) / 2]}
					rotation-y={(-2 * Math.PI) / 2}
				/>
				<Pillar
					type="TV06"
					position={[-(width - CONFIG.wallThickness) / 2, 0, (depth - CONFIG.wallThickness) / 2]}
					rotation-y={(-2 * Math.PI) / 2}
				/>
				<Wall location="front" position={[0, 0, (depth - CONFIG.wallThickness) / 2]} />
				<Wall location="back" position={[0, 0, -(depth - CONFIG.wallThickness) / 2]} rotation-y={Math.PI} />
				<Wall location="left" position={[-(width - CONFIG.wallThickness) / 2, 0, 0]} rotation-y={-Math.PI / 2} />
				<Wall location="right" position={[(width - CONFIG.wallThickness) / 2, 0, 0]} rotation-y={Math.PI / 2} />
			</group>

			<group position-x={-(width - CONFIG.wallThickness) / 2}>
				<Pillar
					type="HV06"
					position={[-(loungeWidth - CONFIG.wallThickness) / 2, 0, -(depth - CONFIG.wallThickness) / 2]}
				/>
				<Pillar
					type="HV06"
					position={[-(loungeWidth - CONFIG.wallThickness) / 2, 0, (depth - CONFIG.wallThickness) / 2]}
					rotation-y={Math.PI / 2}
				/>

				<Wall location="loungeFront" position={[0, 0, (depth - CONFIG.wallThickness) / 2]} />
				<Wall location="loungeBack" position={[0, 0, -(depth - CONFIG.wallThickness) / 2]} rotation-y={Math.PI} />
				<Wall
					location="loungeSide"
					position={[-(loungeWidth - CONFIG.wallThickness) / 2, 0, 0]}
					rotation-y={(3 * Math.PI) / 2}
				/>
			</group>
		</>
	);
};

export default ShedWithLounge;
