import { PropsWithChildren } from "react";
import styled from "styled-components";

type Variant = "info" | "warning";
type CalloutProps = PropsWithChildren<{
	variant?: Variant;
}>;

const Container = styled.div<{ $variant: Variant }>`
	font-weight: 300;
	margin: ${({ theme }) => theme.gap.medium} 0;
	padding: ${({ theme }) => theme.gap.medium};
	background-color: ${({ $variant, theme }) => ($variant === "info" ? theme.colors.info : theme.colors.warning)};
`;

export default function Callout({ children, variant = "info" }: CalloutProps) {
	return <Container $variant={variant}>{children}</Container>;
}
