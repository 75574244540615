import CONFIG from "@/config";
import Pillar from "../Pillar";
import Wall from "../Wall";
import { FunctionComponent } from "react";
import { Roof } from "@/config/roofs";

const Shed: FunctionComponent<Roof> = ({ width, depth }) => {
	return (
		<>
			<group name="pillars">
				<Pillar
					type="HV06"
					position={[-(width - CONFIG.wallThickness) / 2, 0, -(depth - CONFIG.wallThickness) / 2]}
					rotation-y={(-0 * Math.PI) / 2}
				/>
				<Pillar
					type="HV06"
					position={[(width - CONFIG.wallThickness) / 2, 0, -(depth - CONFIG.wallThickness) / 2]}
					rotation-y={(-1 * Math.PI) / 2}
				/>
				<Pillar
					type="HV06"
					position={[(width - CONFIG.wallThickness) / 2, 0, (depth - CONFIG.wallThickness) / 2]}
					rotation-y={(-2 * Math.PI) / 2}
				/>
				<Pillar
					type="HV06"
					position={[-(width - CONFIG.wallThickness) / 2, 0, (depth - CONFIG.wallThickness) / 2]}
					rotation-y={(-3 * Math.PI) / 2}
				/>
			</group>

			<group name="walls">
				<Wall location="front" position={[0, 0, (depth - CONFIG.wallThickness) / 2]} />
				<Wall location="back" position={[0, 0, -(depth - CONFIG.wallThickness) / 2]} rotation-y={Math.PI} />
				<Wall location="left" position={[-(width - CONFIG.wallThickness) / 2, 0, 0]} rotation-y={-Math.PI / 2} />
				<Wall location="right" position={[(width - CONFIG.wallThickness) / 2, 0, 0]} rotation-y={Math.PI / 2} />
			</group>
		</>
	);
};

export default Shed;
