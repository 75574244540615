import CONFIG from "@/config";
import { getRoofById, getWallById, getWallsByWidth, isUndefined } from "@/utils";
import { Color } from "three";
import { Wall, WallLocation } from "@/config/walls";
import { Roof } from "@/config/roofs";
import { State, WallConfiguration } from "./store";

export const selectors = {
	isWallSelected: (state: State) => !isUndefined(state.selectedWall),
	getRoofBySelected: (state: State): Roof => {
		return getRoofById(state.configuration.roof);
	},

	selectedIsLounge: (state: State) => selectors.getRoofBySelected(state).loungeWidth > 0,

	getAllWalls: (state: State): Partial<WallConfiguration> => {
		const isLounge = selectors.selectedIsLounge(state);
		const getShedWalls = (walls: WallConfiguration) => {
			return {
				front: walls.front,
				back: walls.back,
				left: walls.left,
				right: walls.right,
			};
		};
		return isLounge ? state.configuration.walls : getShedWalls(state.configuration.walls);
	},

	getWallBySelected: (state: State): Wall | undefined => {
		const wall = state.selectedWall && state.configuration.walls[state.selectedWall];
		return wall?.id && getWallById(wall.id);
	},

	getWallIdBySelected: (state: State): Wall["id"] | undefined => {
		return selectors.getWallBySelected(state)?.id;
	},

	getWallOptionBySelected: (option: "electricity" | "innerWall") => {
		return (state: State) => {
			const wall = state.selectedWall && state.configuration.walls[state.selectedWall];
			return Boolean(wall?.[option]);
		};
	},

	getAvailableWallsByLocation: (location?: WallLocation) => {
		return (state: State): Wall[] => {
			const selectedRoof = selectors.getRoofBySelected(state);

			switch (location) {
				case "front":
				case "back":
					return getWallsByWidth(selectedRoof.width);
				case "left":
				case "right":
				case "loungeSide":
					return getWallsByWidth(selectedRoof.depth);
				case "loungeFront":
				case "loungeBack":
					return getWallsByWidth(selectedRoof.loungeWidth);
				default:
					return [];
			}
		};
	},

	getAvailableWallsBySelected: (state: State) => {
		return selectors.getAvailableWallsByLocation(state.selectedWall)(state);
	},

	getWallByLocation: (location: WallLocation) => {
		return (state: State): Wall | undefined => {
			const wall = state.configuration.walls[location];
			return wall.id && getWallById(wall.id);
		};
	},

	getWallSize: (location?: WallLocation) => {
		return (state: State): number => {
			const selectedRoof = selectors.getRoofBySelected(state);

			switch (location) {
				case "front":
				case "back":
					return selectedRoof.width;
				case "left":
				case "right":
				case "loungeSide":
					return selectedRoof.depth;
				case "loungeFront":
				case "loungeBack":
					return selectedRoof.loungeWidth;
				default:
					return Infinity;
			}
		};
	},

	getSelectedColor: (state: State) => state.configuration.color,
	getPillarColor: (state: State): Color =>
		CONFIG.colors[state.configuration.color === "white" ? "white" : "anthracite"],
	getWallColor: (state: State): Color =>
		CONFIG.colors[state.configuration.color === "duo" ? "white" : state.configuration.color],

	getWallEncoding: (wallLocation: WallLocation) => {
		return (state: State): string => {
			const color = selectors.getSelectedColor(state);
			const { id, electricity, innerWall } = state.configuration.walls[wallLocation];

			if (isUndefined(id)) return "";

			const wall = getWallById(id);
			const wallCode = wall.segments.join("");
			const electricityCode = electricity ? "E" : "V";
			const innerWallCode = innerWall ? "F" : "N";
			const colorCode = color === "white" ? "W" : color === "anthracite" ? "A" : "G";

			return `${wallCode}-${innerWallCode}${electricityCode}${colorCode}-00`;
		};
	},

	getAllWallEncodings: (state: State) => {
		const walls = selectors.getAllWalls(state);
		return Object.fromEntries(
			Object.keys(walls).map((location) => [location, selectors.getWallEncoding(location as WallLocation)(state)])
		);
	},

	getRoofEncoding: (state: State) => {
		const roof = selectors.getRoofBySelected(state);
		return roof.id;
	},

	getConfiguration: (state: State) => ({
		roof: selectors.getRoofEncoding(state),
		walls: selectors.getAllWallEncodings(state),
	}),

	getConfigurationSerialized: (state: State) => {
		const configuration = selectors.getConfiguration(state);
		return JSON.stringify(configuration, null, 4);
	},
};
