import CONFIG from "@/config";
import { selectors, useAppStore } from "@/stores/appStore";
import Measured, { RenderCallback } from "../../Utils/Measured";
import { Box, Text } from "@react-three/drei";
import { useCallback } from "react";
import { ErrorBoundary } from "@sentry/react";
import SimpleShed from "./SimpleShed";
import ShedWithLounge from "./ShedWithLounge";
import Roof from "../Roof";
import { anthraciteMaterial } from "../Wall/Segments/constants";

const Shed = () => {
	const showMeasurements = useAppStore((store) => store.showMeasurements);
	const isLounge = useAppStore(selectors.selectedIsLounge);
	const roof = useAppStore(selectors.getRoofBySelected);
	const { name, width, loungeWidth, depth } = roof;

	const renderLabel = useCallback<RenderCallback>(
		(_, axis) => (
			<Text fontSize={0.1} color="black">
				{`${(axis === "X" ? width : axis === "Z" ? depth : CONFIG.wallHeight).toFixed(2)} m`}
			</Text>
		),
		[width, depth]
	);

	return (
		<group name="shed">
			<Measured visible={showMeasurements} color="black" render={renderLabel} offset={0.5}>
				{isLounge ? <ShedWithLounge {...roof} /> : <SimpleShed {...roof} />}
			</Measured>

			<ErrorBoundary
				fallback={
					<Box
						castShadow
						args={[width + loungeWidth - CONFIG.wallThickness, 0.1, depth]}
						position-y={CONFIG.wallHeight + 0.05}
						material={anthraciteMaterial}
					/>
				}>
				<Roof type={name} position-y={CONFIG.wallHeight} />
			</ErrorBoundary>
		</group>
	);
};

export default Shed;
