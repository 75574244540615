import CONFIG from "@/config";
import { isUndefined } from "@/utils";
import { PerspectiveCamera } from "@react-three/drei";
import { useRef } from "react";
import { Layers } from "three";

export default function Camera() {
	const layers = useRef<Layers | undefined>(undefined);

	if (isUndefined(layers.current)) {
		layers.current = new Layers();
		layers.current.enable(1);
	}

	return (
		<PerspectiveCamera
			makeDefault
			fov={45}
			near={0.1}
			far={100}
			name="Main Camera"
			layers={layers.current}
			position={[0, CONFIG.cameraHeight, CONFIG.cameraDistance]}
		/>
	);
}
