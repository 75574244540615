import { RootState, useThree as useThreeFiber } from "@react-three/fiber";
import { createContext, useContext, useEffect, useState } from "react";

const ThreeContext = createContext<{ three: RootState | undefined; setThree: (three: RootState) => void }>({
	three: undefined,
	setThree: () => void {},
});

export const ThreeProvider = ({ children }: { children: React.ReactNode }) => {
	const [three, setThree] = useState<RootState | undefined>(undefined);
	return <ThreeContext.Provider value={{ three, setThree }}>{children}</ThreeContext.Provider>;
};

export const SyncStore = () => {
	const { setThree } = useContext(ThreeContext);
	const three = useThreeFiber();

	useEffect(() => {
		setThree(three);
	}, [three, setThree]);

	return null;
};

const useThree = () => {
	const { three } = useContext(ThreeContext);

	return three;
};

export default useThree;
