import usePrice from "@/hooks/usePrice";
import styled from "styled-components";
import ArrowLink from "./Arrow";
import { isUndefined } from "@/utils";
import useNavigation from "@/hooks/useNavigation";

export default function Footer() {
	const { formattedPrice } = usePrice();
	const { prev, next } = useNavigation();

	return (
		<FooterContainer>
			<StyledArrowLink direction="left" disabled={isUndefined(prev)} to={prev} />
			<p>{formattedPrice}</p>
			<StyledArrowLink direction="right" disabled={isUndefined(next)} to={next} />
		</FooterContainer>
	);
}

const FooterContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: ${({ theme }) => theme.colors.background_light};
	font-size: 1.25rem;
	font-weight: 300;
	padding: 1rem;
	text-align: center;
	margin-top: auto;
	padding: ${({ theme }) => theme.padding.medium};
`;

const StyledArrowLink = styled(ArrowLink)`
	width: 2.5rem;
	height: 2.5rem;
	padding: 0.5em;

	&[aria-disabled="true"] {
		visibility: hidden;
	}

	&:link,
	&:visited {
		color: ${({ theme }) => theme.colors.dark_grey};
	}
`;
