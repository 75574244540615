import { z } from "zod";
import { isUndefined } from "@/utils";
import { selectors, useAppStore } from "@/stores/appStore";
import translations, { DEFAULT_LOCALE } from "@/translations";

const wallSchema = z
	.object({
		id: z.string(),
		electricity: z.boolean(),
		innerWall: z.boolean(),
	})
	.partial();

const baseSchema = z.object({
	isLounge: z.boolean(),
	roof: z.string(),
	walls: z.object({
		left: wallSchema,
		right: wallSchema,
		front: wallSchema,
		back: wallSchema,
		loungeSide: wallSchema,
		loungeBack: wallSchema,
		loungeFront: wallSchema,
	}),
	color: z.string(),
});

type Test = (data: z.infer<typeof baseSchema>) => boolean;

const hasDrainage: Test = (data) => Object.values(data.walls).some(({ id }) => id?.includes("FAW"));

const hasAllWalls: Test = (data) => {
	const shedWalls = [data.walls.left, data.walls.right, data.walls.front, data.walls.back];
	const loungeWalls = [...shedWalls, data.walls.loungeBack, data.walls.loungeSide, data.walls.loungeFront];
	const isValidConfiguration = (data.isLounge ? loungeWalls : shedWalls).every((wall) => !isUndefined(wall.id));

	return isValidConfiguration;
};

const loungeHasSingleOpenWall: Test = (data) => {
	const numberOfOpenWalls = Object.values(data.walls).filter(({ id }) => id?.includes("LOU")).length;
	return numberOfOpenWalls <= 1;
};

// TODO: Add validation that a shed has no open walls.

const schema = baseSchema
	.refine(hasDrainage, { message: translations.noDrainageWarning[DEFAULT_LOCALE] })
	.refine(hasAllWalls, { message: translations.wallsMissingWarning[DEFAULT_LOCALE] })
	.refine(loungeHasSingleOpenWall, { message: translations.singleOpenWallWarning[DEFAULT_LOCALE] });

export const useValidation = () => {
	const isLounge = useAppStore(selectors.selectedIsLounge);
	const configuration = useAppStore((store) => store.configuration);
	const result = schema.safeParse({ ...configuration, isLounge });
	const isValid = result.success;
	const allMessages = result.error?.errors.map((error) => error.message);
	const message = allMessages?.[0];

	return { isValid, message, allMessages };
};
