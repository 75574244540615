import { canvasToBlobAsync } from "@/utils";
import { useMemo } from "react";
import { ACESFilmicToneMapping, PerspectiveCamera, Scene, SRGBColorSpace, WebGLRenderer } from "three";

type Option = {
	fov?: number;
	resolution?: [number, number];
	position: [number, number, number];
	lookAt: [number, number, number];
};

const createCanvas = (width: number, height: number) => {
	const canvas = document.createElement("canvas");

	canvas.width = width;
	canvas.height = height;

	return canvas;
};

const setupRenderer = (canvas: HTMLCanvasElement) => {
	const renderer = new WebGLRenderer({ antialias: true, alpha: true, canvas });

	renderer.setPixelRatio(window.devicePixelRatio);
	renderer.shadowMap.enabled = true;
	renderer.outputColorSpace = SRGBColorSpace;
	renderer.toneMapping = ACESFilmicToneMapping;
	renderer.toneMappingExposure = 1;

	return renderer;
};

const setupCamera = (option: Option) => {
	const camera = new PerspectiveCamera(90);

	camera.position.set(...option.position);
	camera.lookAt(...option.lookAt);

	return camera;
};

const createScreenshotFn = (scene: Scene) => {
	const singleScreenshot = (option: Option) => {
		const offlineCanvas = createCanvas(...(option.resolution ?? [800, 600]));
		const offlineRenderer = setupRenderer(offlineCanvas);
		const offlineCamera = setupCamera(option);
		const oldBackground = scene.background;

		scene.background = null;
		offlineRenderer.render(scene, offlineCamera);
		scene.background = oldBackground;

		return canvasToBlobAsync(offlineRenderer.domElement, "image/png");
	};

	const multipleScreenshot = (options: Option[]) => {
		return Promise.all(options.map(singleScreenshot));
	};

	function screenshot(options: Option): Promise<Blob>;
	function screenshot(options: Option[]): Promise<Blob[]>;
	function screenshot(options: Option | Option[]) {
		return Array.isArray(options) ? multipleScreenshot(options) : singleScreenshot(options);
	}

	return screenshot;
};

const useScreenshot = (scene: Scene) => useMemo(() => createScreenshotFn(scene), [scene]);

export default useScreenshot;
