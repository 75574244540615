/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.3 public/models/23FA-00.glb --types --exportdefault --root public --precision 6 
*/

import * as THREE from "three";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import { GroupProps } from "@react-three/fiber";
import { WallLocation, wallSizes } from "@/config/walls";
import { selectors, useAppStore } from "@/stores/appStore";
import CONFIG from "@/config";
import useClone from "@/hooks/useClone";
import useCSG from "@/hooks/useCSG";
import Vent from "./Segments/Vent";

const VENT_OFFSET = 0.5;

type GLTFResult = GLTF & {
	nodes: {
		["23FA-Beams"]: THREE.Mesh;
		["23FA-Exterior"]: THREE.Mesh;
		["23FA-Interior"]: THREE.Mesh;
		["23FA-Sides"]: THREE.Mesh;
		["23FA-Skirting"]: THREE.Mesh;
		["23FA-Vents"]: THREE.Mesh;
	};
	materials: {
		["Wall - Beams"]: THREE.MeshStandardMaterial;
		["Wall - Exterior"]: THREE.MeshStandardMaterial;
		["Wall - Interior"]: THREE.MeshStandardMaterial;
		["Wall - Sides"]: THREE.MeshStandardMaterial;
		["Wall - Skirting"]: THREE.MeshStandardMaterial;
		["Wall - Vents"]: THREE.MeshStandardMaterial;
	};
	animations: GLTFAction[];
};

interface EmptyWallProps extends GroupProps {
	showVents?: boolean;
	showInside?: boolean;
	inverseScalingFactor?: number;
	location: WallLocation;
	opacity?: number;
}

export default function EmptyWall({
	children,
	location,
	opacity = 1,
	showVents,
	showInside = true,
	inverseScalingFactor = 1,
	...props
}: EmptyWallProps) {
	const { nodes, materials } = useGLTF("/models/23FA-00.glb") as GLTFResult;
	const wallColor = useAppStore(selectors.getWallColor);
	const wallWidth = wallSizes.inner.S / inverseScalingFactor;
	const ventPosition = ((wallWidth - VENT_OFFSET) / 2) * inverseScalingFactor;

	const visible = opacity === 1;
	const materialProps = {
		"material-color": CONFIG.colors.anthracite,
		"material-roughness": 1,
		"material-metalness": 0,
		"material-transparent": !visible,
		"material-opacity": opacity,
		"material-depthWrite": visible,
	};

	const exterior = useCSG(nodes["23FA-Exterior"], "subtract");
	const interior = useCSG(nodes["23FA-Interior"], "subtract");
	const skirting = useCSG(nodes["23FA-Skirting"], "subtract");

	const exteriorMaterialClone = useClone(materials["Wall - Exterior"]);
	const interiorMaterialClone = useClone(materials["Wall - Interior"]);
	const skirtingMaterialClone = useClone(materials["Wall - Skirting"]);

	return (
		<group {...props} dispose={null}>
			<mesh
				castShadow={props.castShadow}
				geometry={exterior.geometry}
				material={exteriorMaterialClone}
				{...materialProps}
				material-color={wallColor}
				userData={{ location }}
			/>
			<mesh
				castShadow={props.castShadow}
				geometry={interior.geometry}
				material={interiorMaterialClone}
				{...materialProps}
				material-color={wallColor}
				userData={{ location }}
				visible={showInside}
			/>
			<mesh
				castShadow={props.castShadow}
				geometry={nodes["23FA-Sides"].geometry}
				material={materials["Wall - Sides"]}
				{...materialProps}
				userData={{ location }}
			/>
			<mesh
				castShadow={props.castShadow}
				geometry={skirting.geometry}
				material={skirtingMaterialClone}
				{...materialProps}
				material-color={CONFIG.colors.anthracite}
				userData={{ location }}
			/>
			<Vent visible={showVents} scale-x={inverseScalingFactor} position-x={-ventPosition} />
			<Vent visible={showVents} scale-x={inverseScalingFactor} position-x={ventPosition} />
			{children}
		</group>
	);
}

useGLTF.preload("/models/23FA-00.glb");
