import { ReactNode } from "@tanstack/react-router";
import { createContext, useContext, useLayoutEffect, useMemo, useRef, useState } from "react";
import * as BufferGeometryUtils from "three/addons/utils/BufferGeometryUtils.js";
import { BufferGeometry, Group, Mesh } from "three";
import { CSG } from "three-csg-ts";
import { WallLocation } from "@/config/walls";
import { isMesh } from "@/utils";

type CSGContextType = BufferGeometry[];
const CSGContext = createContext<CSGContextType>([]);

const getCSG = (mesh: Mesh): BufferGeometry => {
	const geometry = mesh.geometry.clone();

	if (mesh.parent) {
		mesh.parent.updateMatrix();
		geometry.applyMatrix4(mesh.parent.matrix);
	} else {
		mesh.updateMatrix();
		geometry.applyMatrix4(mesh.matrix);
	}

	return geometry;
};

export function CSGProvider({ location, children }: { location: WallLocation; children: ReactNode }) {
	const groupRef = useRef<Group>(null);
	const [geometries, setGeometries] = useState<BufferGeometry[]>([]);

	useLayoutEffect(() => {
		if (!groupRef.current) return;

		// TODO: don't always set!
		const geometries: BufferGeometry[] = [];
		groupRef.current.traverse((child) => {
			child.userData.location = location;

			if (isMesh(child) && !!child.userData.isCSG) {
				const csg = getCSG(child);
				geometries.push(csg);
			}
		});

		setGeometries(geometries);
	}, [children, location]);

	return (
		<CSGContext.Provider value={geometries}>
			<group ref={groupRef}>{children}</group>
		</CSGContext.Provider>
	);
}

export default function useCSG(baseMesh: Mesh, operation: "union" | "intersect" | "subtract"): Mesh {
	const geometries = useContext(CSGContext);

	return useMemo(() => {
		if (geometries.length === 0) {
			return baseMesh;
		}

		const csgMesh = new Mesh(BufferGeometryUtils.mergeGeometries(geometries));
		const resultMesh = CSG[operation](baseMesh, csgMesh);

		return resultMesh;
	}, [baseMesh, geometries, operation]);
}
