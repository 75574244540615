import { ComponentProps } from "react";
import styled, { css } from "styled-components";

const Image = styled.img<{ $clickable: boolean }>`
	width: 11.25rem;
	transition: opacity 0.15s ease;

	${({ $clickable }) =>
		$clickable &&
		css`
			&:hover {
				opacity: 0.8;
				cursor: pointer;
			}
		`}
`;

const sources = {
	light: "/Telluria-logo.svg",
	dark: "/Telluria-logo-black.svg",
};

interface LogoProps extends Omit<ComponentProps<typeof Image>, "src" | "$clickable"> {
	variant?: keyof typeof sources;
}

const Logo = ({ variant = "dark", ...props }: LogoProps) => (
	<Image alt="TELLURIA Steel of life" loading="eager" src={sources[variant]} $clickable={!!props.onClick} {...props} />
);

export default Logo;
