import { useAppStore } from "@/stores/appStore";
import { CameraControls } from "@react-three/drei";
import { ComponentProps } from "react";
import CameraControlsImpl from "camera-controls";

export default function Controls() {
	const isDevMode = useAppStore((store) => store.isDevMode);

	const productionProps: ComponentProps<typeof CameraControls> = {
		minDistance: 5,
		maxDistance: 50,
		maxPolarAngle: Math.PI / 2,
		mouseButtons: {
			left: CameraControlsImpl.ACTION.ROTATE,
			middle: CameraControlsImpl.ACTION.DOLLY,
			right: CameraControlsImpl.ACTION.NONE,
			wheel: CameraControlsImpl.ACTION.DOLLY,
		},
	};

	const props = isDevMode ? {} : productionProps;

	return <CameraControls makeDefault {...props} />;
}
