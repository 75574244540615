import Checkbox from "@/components/primitives/Checkbox";
import { selectors } from "@/stores/appStore";
import { useAppStore } from "@/stores/appStore";
import translations, { DEFAULT_LOCALE } from "@/translations";
import styled from "styled-components";

export default function WallOptions() {
	const isWallSelected = useAppStore(selectors.isWallSelected);
	const electricity = useAppStore(selectors.getWallOptionBySelected("electricity"));
	const innerWall = useAppStore(selectors.getWallOptionBySelected("innerWall"));
	const toggleOption = useAppStore((store) => store.toggleWallOptionBySelected);

	return (
		isWallSelected && (
			<Container>
				<label>
					<span>{translations.electricity[DEFAULT_LOCALE]}</span>
					<Checkbox checked={electricity} onClick={() => toggleOption("electricity")} />
				</label>
				<label>
					<span>{translations.innerWall[DEFAULT_LOCALE]}</span>
					<Checkbox checked={innerWall} onClick={() => toggleOption("innerWall")} />
				</label>
			</Container>
		)
	);
}

const Container = styled.div`
	display: flex;
	flex-direction: row;
	gap: 1rem;

	span {
		font-weight: 400;
		text-transform: uppercase;
	}

	label {
		display: flex;
		gap: 0.5rem;
		flex-direction: row;
		align-items: center;
	}
`;
