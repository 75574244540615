import { ButtonHTMLAttributes } from "react";
import styled, { css } from "styled-components";

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
	variant?: keyof typeof variants;
	disabled?: boolean;
};

export default function Button({ children, variant = "primary", onClick, ...props }: ButtonProps) {
	function handleClick(e: React.MouseEvent<HTMLButtonElement>) {
		if (props.disabled) return;

		onClick?.(e);
	}

	return (
		<ButtonContainer variant={variant} onClick={handleClick} {...props}>
			{children}
		</ButtonContainer>
	);
}

const variants = {
	primary: css`
		border-color: transparent;
		background-color: ${({ theme }) => theme.colors.primary};
		color: ${({ theme }) => theme.colors.primary_text};

		&:hover {
			border-color: ${({ theme }) => theme.colors.primary};
			background-color: transparent;
			color: ${({ theme }) => theme.colors.primary};
		}
	`,
	secondary: css`
		border-color: ${({ theme }) => theme.colors.primary};
		background-color: transparent;
		color: ${({ theme }) => theme.colors.primary};

		&:hover {
			border-color: transparent;
			background-color: ${({ theme }) => theme.colors.primary};
			color: ${({ theme }) => theme.colors.primary_text};
		}
	`,
	busy: css`
		color: ${({ theme }) => theme.colors.primary_text};
		background: linear-gradient(
			-45deg,
			${({ theme }) => theme.colors.primary},
			${({ theme }) => theme.colors.primary_light},
			${({ theme }) => theme.colors.primary}
		);
		background-size: 400% 400%;
		animation: gradient 1s ease alternate infinite;

		@keyframes gradient {
			0% {
				background-position: 0% 0%;
			}
			100% {
				/* background-position: 50% 75%; */
				background-position: 100% 0%;
			}
		}
	`,
};

const ButtonContainer = styled.button<ButtonProps>`
	all: unset;
	padding: 0.875rem 2rem;
	border: 1px solid transparent;
	transition: background-color 0.15s ease;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-size: 0.625rem;
	font-weight: 400;
	letter-spacing: 1px;
	text-transform: uppercase;

	&:enabled {
		cursor: pointer;
		${({ variant }) => (variant ? variants[variant] : variants.primary)}
	}

	&:disabled {
		cursor: not-allowed;
		background-color: ${({ theme }) => theme.colors.background_light};
	}
`;
