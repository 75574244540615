import Card from "@/components/primitives/Card";
import { CardGrid } from "@/routes/_configurator/colors";
import { selectors, useAppStore } from "@/stores/appStore";

export default function WallSelect() {
	const toggleWallBySelected = useAppStore((store) => store.toggleWallBySelected);
	const options = useAppStore(selectors.getAvailableWallsBySelected);
	const selectedWall = useAppStore(selectors.getWallBySelected);

	return (
		<CardGrid $count={3}>
			{options.map((option) => {
				return (
					<button key={option.id} onClick={() => toggleWallBySelected(option.id)}>
						<Card isActive={selectedWall?.id === option.id} $padding="large">
							<Card.Image src={option.iconURL} $fit="contain" />
						</Card>
					</button>
				);
			})}
		</CardGrid>
	);
}
