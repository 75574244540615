import CONFIG, { ColorVariant, RoofCode } from "@/config";
import prices from "@/config/prices";
import { useAppStore } from "@/stores/appStore";
import { Configuration, WallConfiguration, WallData } from "@/stores/appStore/store";
import { isUndefined } from "@/utils";
import { useCallback } from "react";

const calculateWallPrice = (wall: WallData) => {
	if (isUndefined(wall.id)) return 0;

	const wallPrice = 300;
	const electricityPrice = wall.electricity ? 100 : 0;
	const innerWallPrice = wall.innerWall ? 200 : 0;

	return wallPrice + electricityPrice + innerWallPrice;
};
const sumWallPrices = (total: number, wall: WallData) => total + calculateWallPrice(wall);
const getRoofPrice = (roof: RoofCode) => CONFIG.roofs.byId[roof].price;
const getColorPrice = (color: ColorVariant) => prices.color[color];
const getWallPrice = (walls: WallConfiguration) => Object.values(walls).reduce(sumWallPrices, 0);
const getPrice = (configuration: Configuration) =>
	getRoofPrice(configuration.roof) + getWallPrice(configuration.walls) + getColorPrice(configuration.color);

export default function usePrice() {
	const configuration = useAppStore((store) => store.configuration);
	const price = getPrice(configuration);
	const formattedPrice = price.toLocaleString("nl-NL", { style: "currency", currency: "EUR" });

	const comparePriceToCurrent = useCallback(
		(change: Partial<Configuration>) => {
			const newConfiguration: Configuration = { ...configuration, ...change };
			return getPrice(newConfiguration) - getPrice(configuration);
		},
		[configuration]
	);

	return { price, formattedPrice, getPrice, comparePriceToCurrent };
}
